import { ref, watch } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useRecordsList() {
  // Use toast
  const toast = useToast();

  const refRecordListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "titre", sortable: true },
    { key: "enregistré_le", sortable: true },
    { key: "type", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalRecords = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const filterSortBy = (sortByParam) => {
    switch (sortByParam) {
      case "titre":
        return "name";
      case "enregistré_le":
        return "created_at";
      case "type":
        return "type";
      default:
        return "created_at";
    }
  };

  const refetchData = () => {
    refRecordListTable.value.refresh();
  };

  watch([currentPage, perPage], () => {
    refetchData();
  });

  const fetchRecords = (ctx, callback) => {
    const sortByFiltered = filterSortBy(sortBy.value);
    store
      .dispatch("app-decimmo/findReports", {
        groupId: store.state.auth.userProfile.ID,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortByFiltered,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        const reports = response.data.reports;
        reports.forEach((elem) => {
          if (elem.program) elem.program = JSON.parse(elem.program);
        });
        totalRecords.value = response.data.count;
        callback(reports);
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur lors de la récupération des rapports",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const resolveTypeVariant = (type) => {
    if (type === "PROGRAMME") return "primary";
    return "secondary";
  };

  return {
    fetchRecords,
    tableColumns,
    perPage,
    currentPage,
    totalRecords,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refRecordListTable,

    resolveTypeVariant,
    filterSortBy,
    refetchData,
  };
}
