<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Montrer</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>éléments</label>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refRecordListTable"
        class="position-relative"
        :items="fetchRecords"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucun programme enregistré"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: created_at -->
        <template #cell(titre)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                v-if="data.item.program"
                size="50"
                :src="data.item.program.brochure_url"
              />
              <b-avatar
                v-else
                size="50"
                :src="require('@/assets/images/icons/marker.jpg')"
              />
            </template>
            <b-link
              v-if="data.item.program"
              class="font-weight-bold d-block text-nowrap name-fontsize"
              :to="{
                name: 'decimmo-report',
                query: { id: data.item.ID },
              }"
            >
              {{ data.item.name }}
            </b-link>
            <b-link
              v-else
              class="font-weight-bold d-block text-nowrap name-fontsize"
              :to="{
                name: 'decimmo-report-position',
                query: { id: data.item.ID },
              }"
            >
              {{ data.item.name }}
            </b-link>
            <span class="adress-fontsize d-block" v-if="data.item.program">{{
              data.item.program.promoter_name
            }}</span>
            <span
              class="text-muted d-block adress-fontsize"
              v-if="data.item.program"
              >{{ data.item.program.location.sector_name }} -
              {{ data.item.program.location.street }},
              {{ data.item.program.location.zip }}
              {{ data.item.program.location.city }}</span
            >
          </b-media>
        </template>

        <!-- Column: created_at -->
        <template #cell(enregistré_le)="data">
          <span class="text-nowrap">
            {{ formatReverseDate(data.item.created_at) }}
          </span>
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
          <b-badge
            pill
            :variant="`light-${resolveTypeVariant(data.item.type)}`"
          >
            {{ data.item.type }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button @click="deleteReport(data.item.ID)">
            <feather-icon icon="TrashIcon" size="12" />
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useRecordsList from "./useRecordsList";

// Custom formater
import { formatReverseDate } from "@core/utils/filter";

// Module Situation API Client
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import decimmoStoreModule from "../decimmoStoreModule";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,

    vSelect,
  },
  mixins: [PageBoxed],
  setup() {
    const DECIMMO_APP_STORE_MODULE_NAME = "app-decimmo";

    // Register module
    if (!store.hasModule(DECIMMO_APP_STORE_MODULE_NAME))
      store.registerModule(DECIMMO_APP_STORE_MODULE_NAME, decimmoStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DECIMMO_APP_STORE_MODULE_NAME))
        store.unregisterModule(DECIMMO_APP_STORE_MODULE_NAME);
    });

    const {
      fetchRecords,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refRecordListTable,
      refetchData,

      resolveTypeVariant,
    } = useRecordsList();

    return {
      fetchRecords,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refRecordListTable,
      refetchData,

      resolveTypeVariant,
    };
  },
  methods: {
    formatReverseDate,
    deleteReport(reportID) {
      this.$swal({
        title: "Voulez-vous supprimer ce rapport ?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-secondary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-decimmo/disableReportByID", reportID)
            .then(() => {
              this.refetchData();
              this.$swal({
                icon: "success",
                title: "Rapport supprimé",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch(() => {
              this.$swal({
                icon: "error",
                title: "Impossible de supprimé le rapport",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.name-fontsize {
  font-size: 14px;
}

.adress-fontsize {
  font-size: 11px;
}

.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
